import { Typography, Box,useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SpacePlan from '../img/spaceplan-1024x663.jpg'
import OutSideEvent from "../img/center-outside.png"
import Model3D from "../img/3d-scan-photo.jpg"

const AboutUs = () => {
    const isMatch = useMediaQuery(useTheme().breakpoints.down("md"))
    return (
        <>
        {!isMatch?(
            <Box bgcolor="#2D2E77" padding="4rem">
            <Box display="flex" justifyContent="center" >
                <Typography variant='h2' fontWeight="bold" padding="2rem" color="white" fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }}>關於我們</Typography>
            </Box>
            <Box display="flex" flexDirection="row">
                <Typography width="50%" padding="3rem" justifyItems="center" color="white">
                    捕夢網有限公司（DreamCatcher Prouduction Limited）由香港藝人狄易達先生成立，業務以虛擬空間製作、大型活動策劃及製作、活動統籌、創作設計和燈光音響影像媒體技術為主；為本地及跨國品牌帶來精彩又深刻的活動方案；過往合作品牌其中包括 META公司旗下的INSTAGRAM 、LOGITECH、香港貿易發展局等等。
                </Typography>
                <img src={SpacePlan} width="50%" height="100%hv" alt='About Us' />
            </Box>
        </Box>
        ):(
            <Box bgcolor="#2D2E77" padding="4rem">
                <Box display="flex" justifyContent="center" >
                    <Typography variant='h2' fontWeight="bold" padding="2rem" color="white" fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }}>關於我們</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Typography width="100%" padding="5px" justifyItems="center" color="white" marginBottom="2rem">
                        捕夢網有限公司（DreamCatcher Prouduction Limited）由香港藝人狄易達先生成立，業務以虛擬空間製作、大型活動策劃及製作、活動統籌、創作設計和燈光音響影像媒體技術為主；為本地及跨國品牌帶來精彩又深刻的活動方案；過往合作品牌其中包括 META公司旗下的INSTAGRAM 、LOGITECH、香港貿易發展局等等。
                    </Typography>
                    <img src={SpacePlan} width="100%" height="100%hv" alt='About Us' />
                </Box>
            </Box>
        )}

        {!isMatch?(
            <Box bgcolor="fff" padding="4rem">
            <Box display="flex" flexDirection="row">
                <Box width="50%">
                    <Typography padding="3rem" justifyItems="center" color="#474747" variant='h3' fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }} fontWeight="bold">
                        大型活動製作及统籌
                    </Typography>
                    <Typography padding="3rem" justifyItems="center" color="#474747">
                        2017年更開始舉辦各種大型活動包括演唱會、舞台劇、大型展覽等等，台前幕後一條龍全面策劃服務，使各位客戶得到精彩而又安心無憂的深刻印像。
                    </Typography>
                </Box>
                <img src={OutSideEvent} width="40%" height="100%hv" alt='Event' />
            </Box>
        </Box>
        ):(
            <Box bgcolor="fff" padding="4rem">
                <Box display="flex" flexDirection="column">
                <img src={OutSideEvent} width="100%" height="100%hv" alt='Event' />
                    <Box width="100%">
                        <Typography padding="3rem" justifyItems="center" color="#474747" variant='h3' fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }} fontWeight="bold">
                            大型活動製作及统籌
                        </Typography>
                        <Typography padding="3rem"  justifyItems="center" color="#474747" marginBottom="2rem">
                            2017年更開始舉辦各種大型活動包括演唱會、舞台劇、大型展覽等等，台前幕後一條龍全面策劃服務，使各位客戶得到精彩而又安心無憂的深刻印像。
                        </Typography>
                    </Box>
                    
                </Box>
            </Box>
        )}
        <hr width="80%" />
        {!isMatch? (
            <Box bgcolor="fff" padding="4rem">
            <Box display="flex" flexDirection="row">
                <Box width="50%" padding="3rem">
                    <img src={Model3D} width="90%" height="100%hv" alt='3D model' />
                </Box>
                <Box width="50%">
                    <Typography padding="3rem" justifyItems="center" color="#474747" variant='h3' fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }} fontWeight="bold">
                        虛擬導覽
                    </Typography>
                    <Typography padding="3rem" justifyItems="center" color="#474747">
                        虛擬導覽 – Virtual Tour 是利用尖端的3D 立體掃描器，建構一個360º的立體空間，只需透過電腦、平板電腦、智能手機及VR眼鏡，您和您的客戶能在空間中任意穿梭，參觀空間各個部分的實境，猶如置身在內，及隨意分享。
                    </Typography>
                </Box>
            </Box>
        </Box>
        ):(
            <Box bgcolor="fff" padding="4rem">
                <Box display="flex" flexDirection="column" justifyItems="center">
                    <Box width="100%"  justifyItems="center">
                        <img src={Model3D} width="100%" height="100%hv" alt='3D model' />
                    </Box>
                    <Box width="100%">
                        <Typography padding="3rem" justifyItems="center" color="#474747" variant='h3' fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }} fontWeight="bold">
                            虛擬導覽
                        </Typography>
                        <Typography padding="3rem" justifyItems="center" color="#474747">
                            虛擬導覽 – Virtual Tour 是利用尖端的3D 立體掃描器，建構一個360º的立體空間，只需透過電腦、平板電腦、智能手機及VR眼鏡，您和您的客戶能在空間中任意穿梭，參觀空間各個部分的實境，猶如置身在內，及隨意分享。
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )}
            
        <hr width="80%" />
        </>

    )
}

export default AboutUs