import React from 'react'
import { AppBar, Toolbar, Box, Typography } from "@mui/material"
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Logo from '../img/DC-logo.png'

const Header = () => {
    return (
        <div>
            <AppBar sx={{ position: "sticky", bgcolor: "#2D2E77" }}>
                <Toolbar>
                    <Box sx={{ display: "flex", alignItems: 'center', width: "100%" }} >
                        <PhoneIcon sx={{ padding: 2 }} />
                        <Typography fontSize={{ lg: 14, md: 12, sm: 12, xs: 8 }} variant='h8'>+852 3529 2064</Typography>
                        <MailOutlineIcon sx={{ padding: 2 }} />
                        <Typography fontSize={{ lg: 14, md: 12, sm: 12, xs: 8 }} component='a' href='mailto:info@dreamcatcher-hk.com' variant='h8' sx={{ textDecoration: "none", color: "white" }}>info@dreamcatcher-hk.com</Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: "flex",padding:"1em", alignItems: 'center', width: "100%", flexDirection:"column" }}>
                
                <img width="10%" src={Logo} alt="Logo"></img>
                <Typography color="grey" padding="1rem" variant='h3' fontSize={{ lg: 14, md: 12, sm: 12, xs: 10 }}>Let’s our Dreams catch the dreams We deserve</Typography>
            </Box>
        </div>
    )
}

export default Header