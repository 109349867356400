import { Box, Typography, FormControl, InputLabel, Input, Button, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import FooterImage from '../img/center-outside.png'

const Footer = () => {
    const [email, setEmail] = useState("")
    console.log(email)
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(email)
        setEmail("")
    }
    const isMatch = useMediaQuery(useTheme().breakpoints.down("md"))

    return (
        
        <>
        {!isMatch ? (
            <Box display="flex" flexDirection="row" width="100%">
            <Box sx={{ bgcolor: "#d9d9d9", justifyItems: "center", width: "50%", alignContent: "center" }}>
                <Typography padding="3rem" variant='h3' fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }} sx={{ textAlign: "center" }}>
                    最新資訊
                </Typography>
                <Typography color="#919191" padding="1rem" variant='h5' fontSize={{ lg: 24, md: 18, sm: 14, xs: 12 }} sx={{ textAlign: "center" }}>
                    如欲獲取我們最新資訊或優惠, 可留下電郵
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{alignContent:"center", width: "100%" }}>
                        <InputLabel sx={{ml:10, mr:10}} htmlFor="my-input">輸入電郵地址</InputLabel>
                        <Input sx={{ml:10, mr:10}} type='email' onChange={(e)=>{setEmail(e.target.value)}} id="my-input" aria-describedby="my-helper-text" />
                        
                        
                    </FormControl>
                    <Button sx={{justifyItems: "center", color:"#2D2E77",width: "100%", paddingLeft:10, paddingRight:10}} type='submit'>送出</Button>                     
                </form>
            </Box>
            <img src={FooterImage} alt="footer" width="50%" />
        </Box>
        ):(
            <Box display="flex" flexDirection="column" width="100%">
                <Box sx={{ bgcolor: "#d9d9d9", justifyItems: "center", width: "100%", alignContent: "center" }}>
                    <Typography padding="3rem" variant='h3' fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }} sx={{ textAlign: "center" }}>
                        最新資訊
                    </Typography>
                    <Typography color="#919191" padding="1rem" variant='h5' fontSize={{ lg: 24, md: 18, sm: 14, xs: 12 }} sx={{ textAlign: "center" }}>
                        如欲獲取我們最新資訊或優惠, 可留下電郵
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl sx={{alignContent:"center", width: "100%" }}>
                            <InputLabel sx={{ml:10, mr:10}} htmlFor="my-input">輸入電郵地址</InputLabel>
                            <Input sx={{ml:10, mr:10}} type='email' onChange={(e)=>{setEmail(e.target.value)}} id="my-input" aria-describedby="my-helper-text" />
                            
                            
                        </FormControl>
                        <Button sx={{justifyItems: "center", color:"#2D2E77",width: "100%", paddingLeft:10, paddingRight:10}} type='submit'>送出</Button>                     
                    </form>
                </Box>
                <img src={FooterImage} alt="footer" width="100%"/>
            </Box>
        )}
            <Box sx={{ justifyItems: "center", bgcolor: "#2D2E77" }}>
                <Typography  padding="1rem" fontSize={{ lg: 14, md: 12, sm: 12, xs: 8 }} color="white" variant='h6'>© Dreamcatcher Production Limited 2022</Typography>
            </Box>
        </>
    )
}

export default Footer