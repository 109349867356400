import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import design1 from '../img/design-1.jpg'
import design2 from '../img/design-2.jpg'
import design3 from '../img/design-3.png'
import design4 from '../img/design-4.jpg'
import design5 from '../img/design-5.png'

const OurJob = () => {
    const isMatch = useMediaQuery(useTheme().breakpoints.down("md"))
    return (
        <Box display="flex" flexDirection="column" sx={{mt:4, mb:4}} padding="5rem">
            <Typography width="100%" textAlign="center" variant='h3' fontWeight="bold" fontSize={{ lg: 44, md: 32, sm: 32, xs: 28 }}  >
                平面設計
            </Typography>
            {!isMatch?(
                <Box display="flex" flexDirection="row" justifyItems="center" alignItems="center" width="100%">
                <Box width="25%" padding="1em">
                <img src={design1} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="25%" padding="1em">
                <img src={design2} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="25%" padding="1em">
                <img src={design3} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="25%" padding="1em">
                <img src={design4} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="25%" padding="1em">
                <img src={design5} alt="design1" width="80%" height="100%hv" />
                </Box>
                
            </Box>
            ):(
                <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center" width="100%" padding="2.5rem">
                <Box width="100%" padding="1em">
                <img src={design1} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="100%" padding="1em">
                <img src={design2} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="100%" padding="1em">
                <img src={design3} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="100%" padding="1em">
                <img src={design4} alt="design1" width="80%" height="100%hv" />
                </Box>
                <Box width="100%" padding="1em" alignItems="center">
                <img src={design5} alt="design1" width="80%" height="100%hv" />
                </Box>
                
            </Box>
            )}
            
        </Box>
    )
}

export default OurJob