import AboutUs from "./Components/AboutUs";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import OurJob from "./Components/OurJob";


function App() {
  return (
    <>
      <header>
      <Header />
    </header>
    <section>
      <AboutUs />
      <OurJob />
    </section>
    <footer>
      <Footer />
    </footer>
    </>
    
  );
}

export default App;
